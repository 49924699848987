import React, { useState, useCallback, useMemo } from 'react';
import { Autocomplete, TextField, Chip, Button } from '@mui/material';
import debounce from 'lodash/debounce.js';

const AutocompleteInput = ({
    options,
    popularOptions,
    label,
    filterKey,
    filters,
    handleFilterChange,
    removeFilter,
    onDialogOpen,
    showBrowseAllOptions = true,
    onSearch
}) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const debouncedSearch = useCallback((value) => {
        if (onSearch) {
          setLoading(true);
          onSearch(value).finally(() => setLoading(false));
        }
      }, [onSearch, setLoading]);
      
    const debouncedSearchWithDelay = useMemo(
        () => debounce(debouncedSearch, 300),
        [debouncedSearch]
    );

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        debouncedSearchWithDelay(newInputValue);
    };

    const filterOptions = (options, { inputValue }) => {
        if (inputValue === '') {
            return showBrowseAllOptions 
                ? [...popularOptions, { inputValue: 'browse', label: 'Browse all options...' }]
                : options;
        }
        const filtered = options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
        if (showBrowseAllOptions && filtered.length > 5) {
            return [
                ...filtered.slice(0, 5),
                { inputValue, label: `View all ${filtered.length} results...` },
            ];
        }
        return filtered;
    };

    const getOptionLabel = (option) => {
        if (typeof option === 'string') return option;
        if (option.inputValue) return option.inputValue;
        return option.label;
    };

    const renderOption = (props, option) => {
        if (showBrowseAllOptions && (option.inputValue === 'browse' || option.label?.startsWith('View all'))) {
            return (
                <Button
                    {...props}
                    onClick={() => {
                        onDialogOpen(filterKey);
                        handleClose();
                    }}
                    fullWidth
                    style={{ justifyContent: 'flex-start', textTransform: 'none' }}
                >
                    {option.label}
                </Button>
            );
        }
        return <li {...props}>{option.label || option}</li>;
    };

    const getHelperText = () => {
        if (!showBrowseAllOptions) {
            return `Start typing to search`;
        }
        return `Type to search or click to browse all options`;
    };

    return (
        <Autocomplete
            multiple
            options={options}
            value={filters[filterKey]}
            onChange={(event, newValue) => handleFilterChange(filterKey, newValue)}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            onOpen={handleOpen}
            onClose={handleClose}
            open={open}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={getHelperText()}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={option}
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => removeFilter(filterKey, option)}
                    />
                ))
            }
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) =>
                (option.label && option.label === value) || option === value
            }
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderOption={renderOption}
        />
    );
};

export default AutocompleteInput;