import React, { useState, useEffect } from 'react';
import AutocompleteInput from './AutocompleteInput.js';

const FilterAutocomplete = ({ 
    options, 
    popularOptions,
    label, 
    filterKey, 
    filters, 
    handleFilterChange, 
    removeFilter,
    showBrowseAllOptions = true,
    onSearch,
    onDialogOpen
}) => {
    const [searchOptions, setSearchOptions] = useState(options);

    useEffect(() => {
        setSearchOptions(options);
    }, [options]);

    const handleSearch = async (inputValue) => {
        if (onSearch && !showBrowseAllOptions) {
            const results = await onSearch(inputValue);
            setSearchOptions(results);
        } else {
            // Filter options locally for mechanics and categories
            const filtered = options.filter(option => 
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
            setSearchOptions(filtered);
        }
    };

    return (
        <AutocompleteInput
            options={searchOptions}
            popularOptions={popularOptions || options.slice(0, 5)}
            label={label}
            filterKey={filterKey}
            filters={filters}
            handleFilterChange={handleFilterChange}
            removeFilter={removeFilter}
            showBrowseAllOptions={showBrowseAllOptions}
            onSearch={handleSearch}
            onDialogOpen={onDialogOpen}
        />
    );
};

export default FilterAutocomplete;